
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PermissionsService } from 'projects/kesher-site/src/app/core/permissions/permissions.service';
import {
  AdvMessageService, CRMServices, CopyToClipboardService, FormService, FormValidationsService, LocalizationService,
  MessageTypes, OccupationType, OwnValidators, Role, SideBarService, TableType, enumToArray
} from 'projects/shared/src/public-api';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppState, selecCompanyInCommonState } from '../../../app-state.index';
import { CompaniesService } from '../../../core/services/companies.service';
import { HistoryService } from '../../../kesher/services/history.service';
import { CompanySettingsService } from '../../services/company-settings.service';
import { setCompany } from '../../state/actions/user.actions';
import { CompanyCodeService } from './validators/companyCode/validator';

@Component({
  selector: 'company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {

  @Input() isNew: boolean = false;

  subscriptions = new Subscription();
  companyDetailsForm: FormGroup;
  company: any;
  @Input() userInfo$: Observable<any>;
  user: any;
  occupationsCompanyList: any[];
  companyTypesList: any[];
  crmServicesList: any[] = enumToArray(CRMServices);
  crmServices = CRMServices;
  isKesherManager: boolean = false;
  enableCredit: boolean = false;
  imgPath: any;
  @ViewChild("uploader") uploader: any;
  onSaving: boolean = false;
  changeCompanyStateText: string;
  IsTheCompanyActive: boolean;
  companya:any;

  constructor(
    private formvalidationService: FormValidationsService,
    private formService: FormService,
    private companySettingsService: CompanySettingsService,
    private permissionService: PermissionsService,
    private cdr: ChangeDetectorRef,
    private messageService: AdvMessageService,
    private localizationService: LocalizationService,
    private companyCodeService: CompanyCodeService,
    private sideBarService: SideBarService,
    private router: Router,
    private companyService: CompaniesService,
    private _advMessageService: AdvMessageService,
    private copyToClipboardService: CopyToClipboardService,
    private historyService: HistoryService,
    private store: Store<AppState>,


  ) {
  }

  initForm() {
    this.userInfo$.subscribe(res => {
      this.user = res;
    });
    this.companyDetailsForm = new FormGroup({
      companyID: new FormControl(this.company?.companyID),
      imgPath: new FormControl(this.company?.imgPath),
      name: new FormControl({ value: this.company?.name, disabled: !(this.user.role == Role.KesherUser || this.isNew) }, [Validators.required, Validators.maxLength(100)]),
      companyCode: new FormControl(
        { value: this.company?.companyCode, disabled: this.user.role != Role.KesherUser },
        this.isNew ? [Validators.maxLength(10), OwnValidators.onlyNumbers] :
          [Validators.required, Validators.maxLength(10), OwnValidators.onlyNumbers],
        [(control: FormControl) => this.companyCodeService.asyncCompanyCodeValidator(control, this.isNew)]),
      insertDate: new FormControl({ value: this.isNew ? new Date() : new Date(this.company.insertDate), disabled: true }, [Validators.required]),
      numAssociation: new FormControl(this.company?.numAssociation, [(control: FormControl) => OwnValidators.multipleValidator(control, [OwnValidators.validNumAssociation, OwnValidators.israeliIdentityValidator], false)]),
      address: new FormControl(this.company?.address, [Validators.maxLength(100)]),
      // numHouse: new FormControl(this.company?.numHouse, [Validators.maxLength(8), OwnValidators.onlyNumbers]),
      cityName: new FormControl(this.company?.cityName, [Validators.maxLength(45)]),
      country: new FormControl(this.company?.country, [Validators.maxLength(45)]),
      phone1: new FormControl(this.company?.phone1?.replaceAll('-', ''), [Validators.required, Validators.maxLength(20)]),
      phone2: new FormControl(this.company?.phone2?.replaceAll('-', ''), [Validators.maxLength(20)]),
      mail: new FormControl(this.convertToList(this.company?.mail, ';'), [Validators.required, (control: FormControl) => OwnValidators.ValidListOf(OwnValidators.validMail, control)]),
      fax: new FormControl(this.company?.fax, [Validators.maxLength(15), OwnValidators.validPhone]),
      occupation: new FormControl(this.company?.occupation),
      companyType: new FormControl(this.company?.companyType),
      comment: new FormControl(this.company?.comment, [Validators.maxLength(255)]),
      publicPhone: new FormControl(this.company?.companySettings.publicPhone ? this.company.companySettings.publicPhone?.replaceAll('-', '') : this.company?.phone1?.replaceAll('-', ''), [Validators.maxLength(20)]),
      publicMail: new FormControl(this.convertToList(this.company?.companySettings.publicMail ? this.company?.companySettings.publicMail : this.company?.mail, ';'), [(control: FormControl) => OwnValidators.ValidListOf(OwnValidators.validMail, control)]),
      companyInfo: new FormControl(this.company?.companySettings.companyInfo, [Validators.maxLength(2000)]),
      enableUsingPremiumDigitalForms: new FormControl(this.company?.enableUsingPremiumDigitalForms),
      ezCountPrimaryCompany: new FormControl(this.company?.companySettings.ezCountPrimaryCompany),
      enableCredit: new FormControl(this.company?.companySettings.enableCredit),
      accountDetailsForm: new FormGroup({
        bank: new FormControl(),//this.company?.companySettings.bank
        branch: new FormControl(),//this.company?.companySettings.branch
        account: new FormControl(this.company?.companySettings.account)
      }),
      tz: new FormControl(this.company?.companySettings.tz, [OwnValidators.israeliIdentityValidator]),
      nameAccountOwner: new FormControl(this.company?.companySettings.nameAccountOwner, [Validators.maxLength(45)]),
      moreInfo: new FormControl(this.company?.companySettings.moreInfo, [Validators.maxLength(45)]),
      creditGroup: new FormControl(this.company?.companySettings.creditGroup, [Validators.maxLength(45)]),

      companyReminder: new FormControl(this.company?.reminder?.body, [Validators.maxLength(2000)]),
      crmService: new FormControl(this.company?.companySettings.crmService),
      crmServiceCallbackUrl: new FormControl(this.company?.companySettings?.crmCompaniesService?.actionUrl),
      showDecodingT: new FormControl(this.company?.showDecodingT),
      canAddCompany: new FormControl(this.company?.canAddCompany)

    });
    if (this.isNew)
      this.companyDetailsForm?.get('mail').setValue([this.user.mail]);

    this.cdr.markForCheck();
    this.updateValidation();
  }

  getAccountDetailsFormGroup(): FormGroup {
    return this.companyDetailsForm.controls.accountDetailsForm as FormGroup;
  }

  // setTzValidation() {
  //   this.companyDetailsForm.controls['tz']?.setValidators(!this.enableCredit ? [Validators.required, OwnValidators.israeliIdentityValidator] : [OwnValidators.israeliIdentityValidator]);
  //   if (this.enableCredit)
  //     this.companyDetailsForm.controls['tz'].enable();
  //   else
  //     this.companyDetailsForm.controls['tz'].disable();
  //   this.companyDetailsForm.controls['tz']?.updateValueAndValidity();

  //   this.companyDetailsForm.updateValueAndValidity();
  // }

  ngOnInit(): void {
    this.companySettingsService.getListsToCompanySettings().subscribe((res) => {
      this.occupationsCompanyList = res.occupationsCompanyList;
      this.companyTypesList = res.companyTypesList;
    });

    if (!this.isNew)
      this.companySettingsService.getCompanyDetails().subscribe(res => {
        this.company = res.entity;
        this.imgPath = this.company.imgPath;
        this.changeCompanyStateText = this.company.isActive ? "" : "USER.ENABLE";
        this.IsTheCompanyActive = this.company.isActive ? true : false;
        if (this.company.occupation == OccupationType.Other || this.company.occupation == OccupationType.General || this.company.occupation == null)
          this.messageService.showMessage(MessageTypes.info, this.getTranslation('COMPANY_SETTINGS.COMPANY_DETAILS.HAVE_CHOOSE_OCCUPATION'));
        this.enableCredit = this.company.companySettings.enableCredit;
        this.initForm();
      });
    else {
      this.initForm();
    }
    this.isKesherManager = this.permissionService.isKesherAdminRole();
  }

  result(result: any) {
    if (this.company)
      this.company.imgPath = result?.toString();
    this.companyDetailsForm.controls["imgPath"].patchValue(result);
    this.cdr.detectChanges();
    this.imgPath = result?.toString();
  }

  save() {
    if (this.companyDetailsForm.valid) {
      let form = this.createObjectToSave();
      this.companySettingsService.saveCompanyDetails(form).subscribe(res => {
        if (res.succeeded) {
          var company$ = this.store.select(selecCompanyInCommonState);
          company$.subscribe((res2) => {
            this.companya=res2;
          } );
          this.store.dispatch(setCompany({...this.companya,companyName:form.name,companyCode:form.companyCode,imgPath:form.imgPath }));              
          this.messageService.successMessage(this.localizationService.Translate("GENERAL.MESSAGES.DATA_SAVED_SUCCESSFULLY"));
        }
        else {
          this.messageService.successMessage(this.localizationService.Translate("GENERAL.MESSAGES.FAILED_SAVE_DATA"));
        }
      })
    }
    else {
      this.formvalidationService.markFormGroupTouched(this.companyDetailsForm);
    }
  }

  add() {
    if (this.companyDetailsForm.valid) {
      this.onSaving = true;
      let form = this.createObjectToSave();
      form.companyID = 0;
      this.companySettingsService.addCompany(form).subscribe(res => {
        if (res.succeeded) {
          this.messageService.successMessage(this.localizationService.Translate("GENERAL.MESSAGES.DATA_SAVED_SUCCESSFULLY"));
          this.router.navigate([`/kesher/${res.entity.companyCode}/home`]);
          setTimeout(() => {
            this.sideBarService.close();
            this.cdr.markForCheck();
            this.onSaving = false;
          }, 3000);
        }
        else {
          this.messageService.successMessage(this.localizationService.Translate("GENERAL.MESSAGES.FAILED_SAVE_DATA"));
          this.onSaving = false;
        }
      })
    }
    else {
      this.formvalidationService.markFormGroupTouched(this.companyDetailsForm);
    }
  }

  createObjectToSave() {
    let form = this.formService.getFormValues(this.companyDetailsForm);
    form.mail = form.mail.join(';');

    form.enableUsingPremiumDigitalForms = form.enableUsingPremiumDigitalForms ?? false;
    form.canAddCompany = form.canAddCompany ?? false;
    form.companySettings = {};
    form.companySettings.publicPhone = form.publicPhone;
    form.companySettings.publicMail = form.publicMail.join(';');
    form.companySettings.companyInfo = form.companyInfo;
    form.companySettings.tz = form.tz;
    form.companySettings.ezCountPrimaryCompany = form.ezCountPrimaryCompany ?? false;

    //account details to credit
    let accountDetails = this.formService.getFormValues(this.companyDetailsForm.controls.accountDetailsForm);
    form.companySettings.enableCredit = form.enableCredit ?? false;
    form.companySettings.bank = accountDetails.bank?.id;
    form.companySettings.branch = accountDetails.branch?.id;
    form.companySettings.account = accountDetails.account;
    form.companySettings.nameAccountOwner = form.nameAccountOwner;
    form.companySettings.moreInfo = form.moreInfo;
    form.companySettings.creditGroup = form.creditGroup;
    form.companySettings.crmService = form.crmService;
    form.companySettings.crmCompaniesService = {}
    form.companySettings.crmCompaniesService.actionUrl = form.crmServiceCallbackUrl;

    //reminder
    form.reminder = {};
    form.reminder.body = form.companyReminder;

    return form;
  }

  private getTranslation(translationWord, parameter?) {
    return this.localizationService.Translate(translationWord, parameter)
  }
  private convertToList(string: string, separator: string) {
    string = string?.replace(/\s/g, '') ?? '';
    if (!string.length) return [];
    return string.split(separator);
  }


  beforeChangeCompanyState() {
    this._advMessageService.showConfirmation(
      this.getTranslation('LOGIN.WARNING'),
      this.getTranslation('COMPANY_SETTINGS.COMPANY_STATUS.' + (this.company.isActive ? 'ON_CONFIRMATION_ENSURE' : 'BEFORE_REACTIVATE'), { y: this.getCurrentCompanyName() }),
      () => this.changeCompanyState(),
      null);
  }

  changeCompanyState() {
    this.companyService.changeCompanyState(this.company.companyID).subscribe((res: any) => {
      if (res.succeeded == true) {
        this.company.isActive = !this.company.isActive;
        this.messageService.successMessage(this.getTranslation('COMPANY_SETTINGS.COMPANY_STATUS.' + (this.company.isActive ? 'COMPANY_ACRIVE' : 'COMPANY_INACTIVE')), 5000, true);

        this.changeCompanyStateText = this.company.isActive ? "" : "USER.ENABLE";
        this.refreshPage();
        this.cdr.detectChanges();
      }
      else {
        this.messageService.errorMessage(this.getTranslation('COMPANY_SETTINGS.COMPANY_STATUS.COMPANY_ERROR_CHANGE_STATUS'), 5000, true);
      }
    });
  }

  getCurrentCompanyName(): string {
    return (this.company.name ? (`${this.company.name ?? ''}`) : '')
  }

  refreshPage() {
    window.location.reload();
  }
  copyToClipboard(event) {
    this.copyToClipboardService.copy(event.value);
    this.messageService.successMessage(this.getTranslation('COMPANY_SETTINGS.GENERAL.EMAIL_COPIED'))
  }
  updateValidation() {
    if (this.companyDetailsForm?.get('crmService').value == this.crmServices.general) {
      this.companyDetailsForm.get('crmServiceCallbackUrl').setValidators([Validators.required, Validators.maxLength(255), OwnValidators.validUrl]);
    } else {
      this.companyDetailsForm.get('crmServiceCallbackUrl').clearValidators()
      this.companyDetailsForm.get('crmServiceCallbackUrl').updateValueAndValidity()
    }
  }
  viewChangesHistory() {
    this.historyService.changesHistory(this.company.companyID, TableType.Comapny, "COMPANY_SETTINGS.GENERAL.THIS_COMPANY");

  }
}
